import { Environment } from "../../types/Misc.type";

export const DEVELOPMENT = "development";
export const PRODUCTION = "production";
export const DOWNLOAD_PRODUCTION = "download_prod";
export const WINZO_SPORTS_STAG = "winzo_sports_stag";
export const WINZO_SPORTS_PROD = "winzo_sports_prod";
export const WINZO_DOT_GAMES_PROD = "winzo_games_prod";
export const WINZO_SUPERSTAR_PROD = "winzo_superstar_prod";

export const STAG_WEB_URL = "stag.winzogames.com";
export const LIVE_WEB_URL = "www.winzogames.com";
export const DOWNLOAD_WEB_URL = "download.winzogames.com";
export const WINZO_SPORT_URL = "www.winzosports.com";
export const WINZO_CONSOLE_URL = "console.winzogames.com"
export const WINZO_DOT_GAMES_URL = "winzo.games"
export const WINZO_GLOBAL_URL = "www.winzoglobal.com"
export const WINZO_SUPERSTAR_URL = "www.winzosuperstar.com"
export const WINZO_GAMES_DOT_US = "www.winzogames.us"
export const BRAND_DOT_WINZOGAMES = "brand.winzogames.com"
export const WINZO_DOT_APP = "www.winzo.app"
export const GAMESMANIA_DOT_IN = "www.gamesmania.in"
export const WINFANTASY_DOT_IN = "www.winfantasy.in"

const STAG_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang-stag",
    baseFolder: "website",
    gtmId: "GTM-K3VT27J",
    baseUrl: STAG_WEB_URL,
    shouldIndex: false
}

const PROD_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-WKJX3LD",
    baseUrl: LIVE_WEB_URL,
    shouldIndex: true
}

const DOWNLOAD_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang-download",
    baseFolder: "website",
    gtmId: "GTM-WKJX3LD",
    baseUrl: DOWNLOAD_WEB_URL,
    shouldIndex: false
}

const STAG_SPORTS_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang-stag",
    baseFolder: "website",
    gtmId: "GTM-K3VT27J",
    baseUrl: WINZO_SPORT_URL,
    shouldIndex: false
}

const PROD_SPORTS_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-N99MJV5",
    baseUrl: WINZO_SPORT_URL,
    shouldIndex: false
}

const PROD_WINZO_GAMES_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-NBJSJ55V",
    baseUrl: WINZO_DOT_GAMES_URL,
    shouldIndex: false
}

const PROD_GLOBAL_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-PXF58L6",
    baseUrl: WINZO_GLOBAL_URL,
    shouldIndex: true
}

const PROD_SUPERSTAR_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-KMZF7PQ",
    baseUrl: WINZO_SUPERSTAR_URL,
    shouldIndex: false
}

const PROD_DOT_US_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-K523RS2T",
    baseUrl: WINZO_GAMES_DOT_US,
    shouldIndex: true
}

const BRAND_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-WKJX3LD",
    baseUrl: BRAND_DOT_WINZOGAMES,
    shouldIndex: true
}

const WINZO_DOT_APP_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-NM3DNFCP",
    baseUrl: WINZO_DOT_APP,
    shouldIndex: false
}

const GAMESMANIA_DOT_IN_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-NM3DNFCP",
    baseUrl: GAMESMANIA_DOT_IN,
    shouldIndex: false
}

const WINFANTASY_DOT_IN_WEBSITE_ENV: Environment = {
    baseApiUrl: "https://d3g4wmezrjkwkg.cloudfront.net/",
    langEnv: "lang",
    baseFolder: "website",
    gtmId: "GTM-NM3DNFCP",
    baseUrl: WINFANTASY_DOT_IN,
    shouldIndex: false
}

export function getWebsiteEnvironment(): Environment {
    if (process.env.NEXT_PUBLIC_ENV === DEVELOPMENT)
        return STAG_WEBSITE_ENV;
    else if (process.env.NEXT_PUBLIC_URL === `https://${WINZO_GLOBAL_URL}/`)
        return PROD_GLOBAL_WEBSITE_ENV;
    else if (process.env.NEXT_PUBLIC_URL === `https://${WINZO_GAMES_DOT_US}/`)
        return PROD_DOT_US_WEBSITE_ENV;
    else if (process.env.NEXT_PUBLIC_URL === `https://${BRAND_DOT_WINZOGAMES}/`)
        return BRAND_WEBSITE_ENV;
    else if (process.env.NEXT_PUBLIC_URL === `https://${WINZO_DOT_APP}/`)
        return WINZO_DOT_APP_WEBSITE_ENV;
    else if (process.env.NEXT_PUBLIC_URL === `https://${WINFANTASY_DOT_IN}/`)
        return WINFANTASY_DOT_IN_WEBSITE_ENV;
    else if (process.env.NEXT_PUBLIC_URL === `https://${GAMESMANIA_DOT_IN}/`)
        return GAMESMANIA_DOT_IN_WEBSITE_ENV;
    else if (process.env.NEXT_PUBLIC_ENV === PRODUCTION)
        return PROD_WEBSITE_ENV;
    else if (process.env.NEXT_PUBLIC_ENV === DOWNLOAD_PRODUCTION)
        return DOWNLOAD_WEBSITE_ENV;
    else if (process.env.NEXT_PUBLIC_ENV === WINZO_SPORTS_STAG)
        return STAG_SPORTS_WEBSITE_ENV;
    else if (process.env.NEXT_PUBLIC_ENV === WINZO_SPORTS_PROD)
        return PROD_SPORTS_WEBSITE_ENV;
    else if (process.env.NEXT_PUBLIC_ENV === WINZO_DOT_GAMES_PROD)
        return PROD_WINZO_GAMES_WEBSITE_ENV;
    else if (process.env.NEXT_PUBLIC_ENV === WINZO_SUPERSTAR_PROD)
        return PROD_SUPERSTAR_WEBSITE_ENV;
    else
        return PROD_WEBSITE_ENV;
}

export const isDevelopmentEnv = process.env.NEXT_PUBLIC_ENV === "development";


